<script>
export default {
  name: "MobileHomeHero",
}
</script>

<template>
  <div class="px-4 mb-5 mt-1">
    <h1 class="text-2xl top-2 left-4 font-bold text-indigo-900">
      <span class="text-base font-normal">{{ $t('client.welcome') }},</span> <br>
      {{ $t('client.what_are_you_looking_for') }}
    </h1>
  </div>
</template>